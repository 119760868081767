import React from 'react';
import classNames from 'classnames'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

const widthWindow = document.documentElement.clientWidth >= 768

const LinkItem = ({ title, route, text, color, subTitle, href, id, OpenItem, changePhoto, transition, currentTab, setCurrentTab, photo, isHeader = false, isSide = false }) => {


    return (
        <>
            <Link to={{ pathname: route, search: window.location.search }}
                onClick={() => { setCurrentTab(id); OpenItem(); }}
                className={classNames(styles.BlockLink, {
                    [styles.active]: changePhoto,
                    [styles.transition]: transition,
                    [styles.currentTab]: id === currentTab,
                    
                })}>
                <div className={styles.BlockLinkPhoto} style={isSide ? {height: '75px'} : {}}>
                <svg xmlns="http://www.w3.org/2000/svg"  width="50" height="47" viewBox="0 0 50 47">
                                <image id="firs_logo" data-name="firs logo" width="50" height="47" href={href} />
                            </svg>
                </div>
                <div className={styles.BlockLinkWrapper}>
                
                    <div style={{ background: color }}
                        className={classNames(styles.BlockLinkText)}>
                        <p className="project">{title}<br />{subTitle}</p>
                    </div>
                    
                        <LazyLoadImage
                            alt="image"
                            height="94px"
                            effect="blur"
                            src={photo}
                            width="165px" />
                    
                </div>
            </Link>

        </>
    );
};

export default LinkItem;