import React, { useEffect } from 'react';

const EnableScriptComponent = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn.enable.co.il/licenses/enable-L33267rf0651lph9-1124-65154/init.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // No visible content
};

export default EnableScriptComponent;