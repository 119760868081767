import './App.css';
import HomePage from "./Components/HomePage/homePage";
import EnableScriptComponent from './data/EnableScriptComponent';


function App() {
  return (
    <><HomePage /><EnableScriptComponent /></>
  );
}

export default App;
