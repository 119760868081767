import React from "react";

const LogoComponent = ({ fillColor = "#fdb913" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1547 220"
  >
    <defs>
      <style>
        {`
          .cls-1 { fill: none; }
          .cls-2 { fill: #fff; }
          .cls-3 { fill: ${fillColor}; }
          .cls-4 { fill: #0c282b; }
        `}
      </style>
    </defs>
    <path
      className="cls-3"
      d="M815.63,220c-60.55,0-109.82-49.27-109.82-109.82S755.08.36,815.63.36c26.83,0,52.67,9.78,72.74,27.55l-.88.99c-19.83-17.55-45.35-27.21-71.86-27.21-59.82,0-108.49,48.67-108.49,108.49s48.67,108.49,108.49,108.49v1.33Z"
    />
    <path
      className="cls-3"
      d="M815.63,204.94c52.33,0,94.76-42.42,94.76-94.76s-42.42-94.76-94.76-94.76-94.76,42.43-94.76,94.76,42.42,94.76,94.76,94.76"
    />
    <path
      className="cls-3"
      d="M1455.92,55.69v29.93h59.87v76.22h31.21V55.69h-91.08ZM1410.28,161.84h31.85V55.69h-31.85v106.15ZM1276.53,161.84l120.8-.21v-64.97c0-25.05-15.07-40.97-41.4-40.97h-28.87v30.15h24.84c11.68,0,14.44,5.73,14.44,18.26v27.17h-58.81V55.69l-31,.21v105.94ZM1230.46,55.69v55.62c0,14.44-7.43,20.17-18.26,20.17h-8.92V55.69h-31.63v75.79h-25.9V55.69h-31.42v106.15h101.48c24.63,0,45.43-18.04,45.43-44.16v-61.99h-30.78Z"
    />
    <path
      className="cls-2"
      d="M981,161.84l120.8-.21v-64.97c0-25.05-15.07-40.97-41.4-40.97h-28.87v30.15h24.84c11.68,0,14.44,5.73,14.44,18.26v27.18h-58.81V55.69l-31,.21v105.94ZM934.51,117.68h32.7v-61.99h-32.7v61.99ZM888.65,117.68h32.7v-61.99h-32.7v61.99ZM787.18,131.27v-45.43h42.89c11.25,0,14.44,3.82,14.44,15.92v29.51h-57.32ZM755.75,161.84l120.16-.21v-66.03c0-25.05-15.71-39.91-41.61-39.91h-78.55v106.15Z"
    />
    <rect className="cls-1" x="0.33" y="0.36" width="1546.67" height="219.64" />
    <path
      className="cls-4"
      d="M811.18,78.36v25.52c-2.83,1.24-4.81,4.05-4.81,7.34,0,4.43,3.59,8.01,8.02,8.01.53,0,1.04-.06,1.54-.15l31.87,34.18,4.69-4.37-31.27-33.53c.73-1.21,1.18-2.62,1.18-4.13,0-3.29-1.98-6.1-4.81-7.34v-25.52h-6.41Z"
    />
    <path
      className="cls-2"
      d="M591.19,131.53v-45.75h38.7v21.59c0,17.75-8.34,24.37-22.45,24.16h-16.25ZM559.76,161.89h53.02c29.51,0,48.54-21.81,48.54-51.1v-55.8h-101.56v106.91ZM511.23,161.89h31.86V54.99h-31.86v106.91ZM394.06,161.89h57.94c25.01,0,40.84-14.97,40.84-40.41V54.99h-98.78v59.87h31.22v-29.08h35.92v29.08c0,13.68-2.57,16.68-14.75,16.68h-52.38v30.36ZM344.24,54.99v56.66c0,15.18-7.05,19.67-17.1,19.88V54.99h-31.86v76.54h-16.46V54.99h-31.64v106.91h83.81c23.95,0,44.26-16.89,44.26-43.62v-63.29h-31ZM175.12,161.89h13.68c26.3,0,41.05-13.9,41.05-39.13V54.99h-55.38v30.79h23.73v29.72c0,13.26-1.92,16.04-15.61,16.04h-7.48v30.36ZM65.87,161.89h31.43v-59.22h-31.43v59.22ZM127.66,161.89h31.43V54.99h-94.5v30.36h63.07v76.54Z"
    />
    <rect className="cls-1" x="0.33" y="0.36" width="1546.67" height="219.64" />
    <path
      className="cls-2"
      d="M0.96,161.9h30.47v-30.04H0.96v30.04ZM0.33,121.2h31.74V54.99H0.33v66.21Z"
    />
  </svg>
);

export default LogoComponent;