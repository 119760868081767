import React from 'react';
import styles from './index.module.scss';
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import LinkItem from "../LinkItem/LinkItem";

const BlockRight = ({ arrayDataTab, activeDoorAnimation, currentTab, AllTabs, setActiveDoor, setCurrentTab }) => {
    const isMobile = document.documentElement.clientWidth <= 768;

    const correntData = arrayDataTab.find(tab => tab.id === currentTab)

    return (
        <>
            <div key={`${currentTab}innerDiv`}
                className={classNames(styles.blockDoorSecond, { [styles.active]: activeDoorAnimation })}>
                
                <Swiper 
    loop={!isMobile} 
    initialSlide={0} 
    className={styles.containerSwiper} 
    pagination={{ clickable: true }} 
    modules={[Pagination]} 
    id="swiper"
>
    {correntData?.media?.map(({ type, src, poster, alt }, index) => (
        <SwiperSlide key={index}>
            {type === 'video' ? (
                <video
                    src={src}
                    loop
                    muted
                    autoPlay
                    playsInline
                    poster={poster}
                    style={{ width: '100%', height: '100%' }}
                />
            ) : (
                <LazyLoadImage
                    src={src}
                    alt={alt || 'Image'}
                    width="100%"
                    height="100%"
                    effect="blur"
                    style={{'max-height' : '100vh'}}
                />
            )}
        </SwiperSlide>
    ))}
</Swiper>
                

                {!isMobile && (
                    <div key={`${currentTab}Links`} className={styles.tabs}>
                        {AllTabs?.map(({ title, subTitle, text, color, href, id, route }) => (
                            <LinkItem
                                isSide = {true}
                                key={`${title}${id}`}
                                changePhoto={false}
                                transition={true}
                                OpenItem={() => setActiveDoor(true)}
                                setCurrentTab={() => setCurrentTab(id)}
                                id={id}
                                title={title}
                                subTitle={subTitle}
                                text={text}
                                color={color}
                                href={href}
                                route={route}
                                currentTab={currentTab}
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default BlockRight;
